import { is_mobile_browser, is_firefox } from './ua'

export const storageKeyPrefix = 'OLXBR_ACCOUNTS'
export const legacyRedirectStorageKey = 'RETURN_TO_PARAMS'
export const tokenRedirectStorageKey = 'REDIRECT_TO_TOKEN'

const safeEncodeURI = text => {
  try {
    return window.encodleURIComponent(text)
  } catch (error) {
    return text
  }
}

const hasQueryParam = url => typeof url === 'string' && url.indexOf('?') >= 0

const mountQueryParam = (url, query) => {
  if (typeof query === 'string') {
    return (hasQueryParam(url) ? '&' : '?') + query
  }
  return ''
}

const redirect = (url, query) => {
  const queryParam = mountQueryParam(url, query)
  window.location = url + queryParam
}

const isMobile = () => {
  try {
    return window.screen.width < 576
  } catch (error) {
    return false
  }
}

const cleanValue = value => {
  if (typeof value === 'string') {
    let newValue = value.trim()
    if (newValue.toLocaleLowerCase() === 'null' || newValue === '') newValue = null
    return newValue
  }
  return value
}

const setInfo = (key, value) => {
  const keyWithPrefix = `${storageKeyPrefix}_${key}`
  localStorage.setItem(keyWithPrefix, cleanValue(value))
}

const withParam = name => param => param.split('=')[0].toLocaleLowerCase() === name

export const getInfo = (key, removeAfterReading) => {
  const keyWithPrefix = `${storageKeyPrefix}_${key}`
  const value = localStorage.getItem(keyWithPrefix)
  if (removeAfterReading) localStorage.removeItem(keyWithPrefix)
  return cleanValue(value)
}

export const saveRedirectInfo = () => {
  const querystring = window.location.search

  if (querystring) {
    const questionLess = querystring.split('?')[1]
    const params = questionLess.split('&')

    const isActionType = params.some(withParam('action_type'))
    const isReturnTo = params.some(withParam('return_to'))
    const returnToToken = params.find(withParam('returntotoken'))

    if (isActionType || isReturnTo) {
      setInfo(legacyRedirectStorageKey, querystring)
    } else if (returnToToken) {
      const value = returnToToken.split('=')[1]
      setInfo(tokenRedirectStorageKey, value)
    }
  }
}

export const doRedirect = url => {
  const redirectFn = () => window.location.replace(url)
  if (is_firefox() || is_mobile_browser()) {
    /**
     * Firefox and some mobile browsers might cancel the XHR requests on redirects.
     * This 500ms delay helps Lurker events to be sent before that happens.
     */
    return setTimeout(redirectFn, 500)
  }
  redirectFn()
}

export const redirectUser = (data, flow) => {
  const legacyRedirectInfo = getInfo(legacyRedirectStorageKey, true)
  const tokenRedirectInfo = getInfo(tokenRedirectStorageKey, true)

  let redirectUrl = "https://apigw.olx.com.br"

  // Now we can redirect based on storedInfo
  if (legacyRedirectInfo) {
    redirectUrl += `/v0/redirect${legacyRedirectInfo}`
  } else if (tokenRedirectInfo) {
    redirectUrl += `/v1/redirect/${tokenRedirectInfo}`
  } else {
    redirectUrl += '/v0/redirect'
  }

  // Append isMobile info
  if (redirectUrl.indexOf('?') >= 0) {
    redirectUrl += `&is_mobile=${isMobile()}`
  } else {
    redirectUrl += `?is_mobile=${isMobile()}`
  }

  doRedirect(redirectUrl)
}

export const redirectToPhoneValidation = (data, flow) => {
  const legacyRedirectInfo = getInfo(legacyRedirectStorageKey, true)
  const tokenRedirectInfo = getInfo(tokenRedirectStorageKey, true)

  let redirectUrl = 'https://conta.olx.com.br'

  // Now we can redirect based on storedInfo
  if (legacyRedirectInfo) {
    redirectUrl += `/confirmacao-telefone${legacyRedirectInfo}`
  } else if (tokenRedirectInfo) {
    redirectUrl += `/confirmacao-telefone?returnToToken=${tokenRedirectInfo}`
  } else {
    redirectUrl += '/confirmacao-telefone'
  }

  // Append isMobile info
  if (redirectUrl.indexOf('?') >= 0) {
    redirectUrl += `&is_mobile=${isMobile()}`
  } else {
    redirectUrl += `?is_mobile=${isMobile()}`
  }

  doRedirect(redirectUrl)
}

export const redirectToVerificationIncentive = (data, flow) => {
  const legacyRedirectInfo = getInfo(legacyRedirectStorageKey, true)
  const tokenRedirectInfo = getInfo(tokenRedirectStorageKey, true)

  let redirectUrl = 'https://conta.olx.com.br'

  // Now we can redirect based on storedInfo
  if (legacyRedirectInfo) {
    redirectUrl += `/incentivo-verificacao${legacyRedirectInfo}`
  } else if (tokenRedirectInfo) {
    redirectUrl += `/incentivo-verificacao?returnToToken=${tokenRedirectInfo}`
  } else {
    redirectUrl += '/incentivo-verificacao'
  }

  // Append isMobile info
  if (redirectUrl.indexOf('?') >= 0) {
    redirectUrl += `&is_mobile=${isMobile()}`
  } else {
    redirectUrl += `?is_mobile=${isMobile()}`
  }

  doRedirect(redirectUrl)
}
