import Router from 'next/router'
import { LoginPage } from '@olxbr/accounts-web-components'
import { LoadingBackdrop } from '@olxbr/design-system/web'
import { redirectUser, redirectToVerificationIncentive } from '../../util/redirect'
import {
  getAbTests,
  getEmailSuggestion,
  showPassword,
  getLoginRecaptchaTest,
} from '../../util/abtesting'
import { isNotStrongAction } from '../../util/commons'

const FACEBOOK_APP_ID = process.env.FACEBOOK_ID || '2363498250455231'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onError = this.onError.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onRegisterClick = this.onRegisterClick.bind(this)
    this.onForgotPassword = this.onForgotPassword.bind(this)
    this.onRequireEmail = this.onRequireEmail.bind(this)
    this.onMagicLinkClick = this.onMagicLinkClick.bind(this)
    this.onMagicLinkEmailSentClick = this.onMagicLinkEmailSentClick.bind(this)
    this.onChallengePinCodeCall = this.onChallengePinCodeCall.bind(this)
    this.state = {
      isLoading: false,
      visiblePassword: false,
    }
  }

  componentDidMount() {
    this.setState({ visiblePassword: showPassword() })
  }

  onSubmit() {
    this.setState({ isLoading: true })
  }

  onError() {
    this.setState({ isLoading: false })
  }

  onSuccess(data) {
    this.setState({ isLoading: true }, () => {
      if (isNotStrongAction()) {
        redirectToVerificationIncentive()
        return
      }
      redirectUser(data, 'login')
    })
  }

  onForgotPassword() {
    this.setState({ isLoading: true })
    Router.push('/recuperacao-senha')
  }

  onRegisterClick() {
    this.setState({ isLoading: true })
    Router.push('/cadastro')
  }

  onRequireEmail(facebookUserID, accessToken, name) {
    this.setState({ isLoading: true })
    Router.push(
      `/completar-cadastro?uid=${facebookUserID}&token=${accessToken}&name=${encodeURIComponent(
        name,
      )}`,
    )
  }

  onMagicLinkClick() {
    this.setState({ isLoading: true })
    Router.push('/enviar-email-acesso-sem-senha')
  }

  onMagicLinkEmailSentClick(email) {
    this.setState({ isLoading: true })
    Router.push(`/acesso-sem-senha-email-enviado?email=${email}`)
  }

  onChallengePinCodeCall(token, email) {
    this.setState({ isLoading: true })
    Router.push(`/desafio-email-pin-code?token=${token}&email=${email}`)
  }

  onReactivationLinkClick = email => {
    this.setState({ isLoading: true })
    const params = email ? `?email=${email}` : ''
    Router.push(`/reativacao-conta${params}`)
  }


  render() {
    const { isLoading, visiblePassword } = this.state

    return (
      <React.Fragment>
        {isLoading && <LoadingBackdrop />}
        <LoginPage
          smartLockLogin
          passwordlessSignIn
          emailSuggestion={getEmailSuggestion()}
          facebookAppId={FACEBOOK_APP_ID}
          googleVariant="blue"
          onError={this.onError}
          onForgotPassword={this.onForgotPassword}
          onLoginSuccess={this.onSuccess}
          onRegisterClick={this.onRegisterClick}
          onRequireEmail={this.onRequireEmail}
          onSubmit={this.onSubmit}
          abTests={getAbTests()}
          visiblePassword={visiblePassword}
          recaptchaVersion={getLoginRecaptchaTest()}
          onMagicLinkClick={this.onMagicLinkClick}
          onMagicLinkEmailSentClick={this.onMagicLinkEmailSentClick}
          onChallengePinCodeCall={this.onChallengePinCodeCall}
          onReactivationLinkClick={this.onReactivationLinkClick}
          showReactivationLink
          showHelpingModalLink
        />
      </React.Fragment>
    )
  }
}

export default Login
