import cookies from 'js-cookie'

export const getEmailSuggestion = () => {
  const testAbGroups = cookies.get('TestAB_Groups') || ''
  if (testAbGroups.indexOf('email-suggestions_new') !== -1) {
    return 'autoComplete'
  }
  if (testAbGroups.indexOf('mailcheck-did-you-mean_new') !== -1) {
    return 'didYouMean'
  }
  return 'none'
}

export const showAccountNotFoundTest = () => {
  const testAbGroups = cookies.get('TestAB_Groups') || ''
  return testAbGroups.indexOf('show-acc-not-found-message_new') !== -1
}

export const getPasswordSecurityVersion = () => {
  const testAbGroups = cookies.get('TestAB_Groups') || ''
  if (testAbGroups.indexOf('password-security_A') !== -1) {
    return 'oldLayoutPasswordStrong'
  }
  if (testAbGroups.indexOf('password-security_B') !== -1) {
    return 'newLayoutPasswordWeak'
  }
  if (testAbGroups.indexOf('password-security_C') !== -1) {
    return 'newLayoutPasswordStrong'
  }
  return 'none'
}

export const showPassword = () => {
  const abTests = getAbTests()
  const isMsite = (window.Lurker && window.Lurker.getSiteSourceByUserAgent() === 'msite')
    || window.innerWidth <= 500
  return abTests.indexOf('visible-password_yes') !== -1 && isMsite
}

export const getSplitLoginVariant = () => {
  const abTests = getAbTests()
  const match = abTests.match('split-login-flow_new(1|2)') || [
    undefined,
    undefined,
  ]
  const variant = match[1]
  return parseInt(variant, 10) || 0
}

export const getAbTests = () => cookies.get('TestAB_Groups') || ''

export const getEmailExistTest = () => {
  const abTests = getAbTests()
  return abTests.indexOf('ast-nu-emailvalidator_A') !== -1
}

export const getLoginRecaptchaTest = () => {
  const abTests = getAbTests()
  if (abTests.indexOf('ast-login-recaptcha_A') !== -1) {
    return 'useRecaptcha'
  }
  return 'none'
}

export const shouldUsePasswordlessSignInOnRegister = () => {
  const abTests = getAbTests()
  return abTests.indexOf('passwordless-sign-in-on-register_yes') !== -1
}
