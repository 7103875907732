import {
  getInfo,
  legacyRedirectStorageKey,
  tokenRedirectStorageKey,
} from "./redirect";

export const isNotStrongAction = () => {
  const isFromLegacy = isStrongActionFromLegacy();
  const isFromToken = isStrongActionFromToken();
  return !(isFromLegacy || isFromToken);
};

const isStrongActionFromLegacy = () => {
  const legacyInfo = getInfo(legacyRedirectStorageKey, false);
  return legacyInfo
    ? legacyInfo.includes("AD_AI") || legacyInfo.includes("CHAT_VI")
    : false;
};

const isStrongActionFromToken = () => {
  const tokenRedirectInfo = getInfo(tokenRedirectStorageKey, false);
  const payload = tokenToPayloadJson(tokenRedirectInfo);
  return payload
    ? payload.url.includes("https://conta.olx.com.br/chats") ||
        payload.url.includes("https://www2.olx.com.br/desapega") ||
        payload.url.includes("https://www2.olx.com.br/ai/form/0/")
    : false;
};

const tokenToPayloadJson = (token) => {
  if (!token) return;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const buffer = Buffer.from(base64, "base64");
  const payload = buffer.toString("ascii");
  return JSON.parse(payload);
};
